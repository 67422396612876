import request from '@/router/axios';
import {businessUrl} from "@/config/env"
export const list = (pageNo,pageSize,processId,status,startTime,endTime) => {
    return request({
        url : businessUrl + "specSheet/selectPage",
        method: "post",
        data: {
            pageNo,
            pageSize,
            processId,
            status,startTime,endTime,
            baseSpecSheetId: " "
        }
    })
}
//标准工艺
export const baseList = (pageNo,pageSize,nameOrCode) => {
    return request({
        url : businessUrl + "specSheet/selectPageByBase",
        method: "post",
        data: {
            pageNo,
            pageSize,
            nameOrCode
        }
    })
}
//实际工艺
export const planList = (pageNo,pageSize,wkln,machineMode,machine) => {
    return request({
        url : businessUrl + "specSheetMachineFact/selectPageByMachineFact",
        method: "post",
        data: {
            pageNo,
            pageSize,
            processId: wkln,
            machineModelId: machineMode,
            machineId: machine
        }
    })
}
//编辑
export const edit = (row) => {
    return request({
        url : businessUrl + "specSheet/update",
        method: "put",
        data: {
            ...row
        }
    })
}
//删除
export const remove = (id) => {
    return request({
        url : businessUrl + "specSheetMachineFact/delete",
        method: "delete",
        params: {
            id
        }
    })
}
export const changeStatus = (row) => {
    return request({
        url : businessUrl + "maintenanceOrder/updateStatus",
        method: "put",
        data : {
            id : row.id,
            status : Number(row.status)
        }
    })
}
export const add = (row) => {
    return request({
        url : businessUrl + "maintenanceOrder/insert",
        method: "post",
        data : {
            type: 2,
            ...row,
            status: 1
        }
    })
}
export const  selectByPromayKey = (id,auditStatus) => {
    return request({
        url : businessUrl + "maintenanceOrder/selectByPromayKey",
        method: "put",
        data: {
            id,
            auditStatus
        }
    })
}
export const wklnList = () => {
    return request({
        url : businessUrl + "process/selectAll",
        method: "get"
    })
}
export const machineModelList = () => {
    return request({
        url : businessUrl + "machineModel/selectAll",
        method: "post"
    })
}
export const getMachineByModeId = (id) => {
    return request({
        url : businessUrl + "machine/selectByMachineModelId",
        method: "get",
        params: {
            machineModelId: id
        }
    })
}